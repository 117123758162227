<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :disabled="cant_update_prop" :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Address</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="address" :disabled="true" placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">From - to</label>
          <div class="p-col">
            <Calendar 
              :showOnFocus="false" 
              dateFormat="MM dd, yy" 
              class=""
              :showIcon="true" 
              selectionMode="range"
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="from_to" :disabled="mode=='edit'" placeholder="Choose"
            />
            <input class="input_tmp_validator" id="from_to"/>
          </div>
        </div>
        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Start booking</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="cant_update_prop"
                  v-model="start_booking_date" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="start_booking_date"/>
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">End</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="end_booking_date"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="end_booking_date"
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="p-card-field">
          <h3>ORDER OF BOOKING ({{partArr.length}}/5)</h3>
        </div>
        <packageDescriptions :key="componentKey" v-model="partArr" :mode="mode"/>
        
        <matrixComponent v-if="roundArr.length > 0 && partArr.length > 0" :booking_id="booking_id" :mode="mode" @updateMatrix="updateMatrix" v-model="partArr" :roundArr="roundArr" :dataBook="dataBookingRound" :show="true" :property="selProperty" :key="`matrix-${componentKey}`"/>
        
        <div v-if="mode == 'edit'" class="p-card-field">
          <a @click="toCalendar" class="txt-link pl-3"><i>View on calendar</i></a>
        </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import packageDescriptions from './components/packageDescriptions';
import matrixComponent from './components/matrix';
import moment from "moment-timezone";
export default {
  components: {
    packageDescriptions,
    matrixComponent
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      dataBookingRound: [],
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      roundArr: [],
      // time_zone_local : moment.guess(true),
      componentKey: 0,
      cant_update_prop: false,
      mode_edit: 0,
      booking_id: null
    }
  },
  watch: {
    async "partArr"() {
      if (this.mode == 'add') {
        var dataBookingRoundTmp = [];
        this.partArr.map((m) => {
          let tmp = [];
          for (let i =0; i < this.roundArr.length; i++) {
            let a = m.order_booking - i + this.partArr.length;
            if (a > this.partArr.length) {
              a = a - this.partArr.length;
            } else if (a == 0) {
              a = this.partArr.length;
            }  else if (a < 0) {
              a = a + this.partArr.length;
            }
            tmp.push({ordinal: a, part_id: m.id, round: (i+1), uid: m?.user?.id, part_number: m.part});
          }
          dataBookingRoundTmp.push(tmp);
        });
        
        this.dataBookingRound = dataBookingRoundTmp;
      }
    },
    async "selProperty"() {
      if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
        this.address = this.selProperty?.address;
        if (this.mode == 'add' || this.mode == 'edit' && this.mode_edit > 0 && !this.cant_update_prop) {
          this.partArr = this.selProperty?.part;
          this.dataBookingRound = [];
          var arr = [];
          this.partArr.map(m => {
            arr.push({ord: m.order_booking, id: m.id, uid: m?.user?.id, part_number: m?.part});
          })
          if ((this.mode == 'add' || !this.cant_update_prop) && this.from_to && !this.$commonFuction.isEmpty(this.from_to[0]) && !this.$commonFuction.isEmpty(this.from_to[1])) {
            let week_diff = (moment(this.from_to[1]).add(1, 'days')).diff(moment(this.from_to[0]), 'week');
            let roundArr = [];
            if (this.partArr.length > 0) {
              let roundLength = Math.floor((week_diff/2)/this.partArr.length) > 5 ? 5 : (Math.floor((week_diff/2)/this.partArr.length) < 1 ? 1: Math.floor((week_diff/2)/this.partArr.length));
              for (let i = 1; i <= roundLength;i++) {
                roundArr.push(i);
              }
            }
            this.roundArr = roundArr;
          }
          arr.map(m=> {
            var tmp = [];
            for (let i =0; i < this.roundArr.length; i++) {
              let a = m.ord - i + arr.length;
              if (a > arr.length) {
                a = a - arr.length;
              } else if (a == 0) {
                a = arr.length;
              }  else if (a < 0) {
                a = a + arr.length;
              }
              tmp.push({ordinal: a, part_id: m.id, round: (i+1), uid: m.uid, part_number: m.part_number});
            }
            this.dataBookingRound.push(tmp);
          })
        }
        // if (this.start_booking_date) {
        //   let days = 14 * this.partArr.length * this.partArr.length;
        //   this.end_booking_date = moment(this.start_booking_date).add(days, 'days').format("LL");
        // }
        this.componentKey++;
      }
    },
    "start_booking_date"() {
      if ((this.mode == 'add' || !this.cant_update_prop) && this.from_to && !this.$commonFuction.isEmpty(this.from_to[0]) && !this.$commonFuction.isEmpty(this.from_to[1])) {
        let week_diff = (moment(this.from_to[1]).add(1, 'days')).diff(moment(this.from_to[0]), 'week');
        let days = ((week_diff/2) - 1) > 0 ? (week_diff/2) - 1 : 1;
        this.end_booking_date = moment(this.start_booking_date).add(days, 'days').format("LL");
      }
    },
    "from_to"() {
      if ((this.mode == 'add' || !this.cant_update_prop) && this.from_to && !this.$commonFuction.isEmpty(this.from_to[0]) && !this.$commonFuction.isEmpty(this.from_to[1])) {
        let week_diff = (moment(this.from_to[1]).add(1, 'days')).diff(moment(this.from_to[0]), 'week');
        let roundArr = [];
        if (this.partArr.length > 0) {
          let roundLength = Math.floor((week_diff/2)/this.partArr.length) > 5 ? 5 : (Math.floor((week_diff/2)/this.partArr.length) < 1 ? 1: Math.floor((week_diff/2)/this.partArr.length));
          for (let i = 1; i <= roundLength;i++) {
          // for (let i = 1; i <= Math.floor((week_diff/2)/this.partArr.length);i++) {
            roundArr.push(i);
          }
          if (this.mode == 'add') {
            this.dataBookingRound = [];
            this.partArr.map(m=> {
              var tmp = [];
              for (let i =0; i < roundArr.length; i++) {
                let a = m.order_booking - i + this.partArr.length;
                if (a > this.partArr.length) {
                  a = a - this.partArr.length;
                } else if (a == 0) {
                  a = this.partArr.length;
                }  else if (a < 0) {
                  a = a + this.partArr.length;
                }
                tmp.push({ordinal: a, part_id: m.id, round: (i+1), uid: m?.user?.id, part_number: m.part});
              }
              this.dataBookingRound.push(tmp);
            })
          }
        }
        console.log("roundArr", roundArr);
        if (this.start_booking_date) {
          let days = ((week_diff/2) - 1) > 0 ? (week_diff/2) - 1 : 1;
          this.end_booking_date = moment(this.start_booking_date).add(days, 'days').format("LL");
        }
        this.roundArr = roundArr;
        this.componentKey++;
      }
    }
  },
  async mounted() {
    this.mode_edit = 0;
    await this.getListProperty();
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model",this.model)
      this.booking_id = this.model?.id;
      this.selProperty = {id : this.model.property.id, name : this.model.property.name, code: this.model.property.code, address: this.model.property.address, part: this.model.property.part, time_zone: this.model.property.time_zone};
      this.from_to = [new Date(moment(this.model.start_visit_date)), new Date(moment(this.model.end_visit_date))];
      // var arr = [];
      await Promise.all(this.model.booking_round.map((l) => {
        // arr.push({id: m.id});
        let part_tmp = {
          id: l.part_id,
          part: l.part_number,
          order_booking: l.ordinal,
          user: l.part_user
        }
        if (this.partArr.filter(f => part_tmp?.id == f?.id).length == 0){
          this.partArr.push(part_tmp);
        }
      })).then(() => {
        // let bookingCheck = [];
        
        // let week_diff = moment(moment(this.model.end_visit_date)).diff(moment(this.model.start_visit_date), 'week');
        // let roundArr = [];
        // if (this.partArr.length > 0) {
        //   for (let i = 1; i <= Math.floor((week_diff/2)/this.partArr.length);i++) {
        //     roundArr.push(i);
        //   }
        // }
        // this.roundArr = roundArr;
      // for ( let i=0; i < this.model.property.part.length; i++) {
        console.log("this.roundArr1", this.roundArr);
        this.partArr.map((m) => {
          let tmp = [];
          // for ( let k=1; k <= this.model.property.part.length; k++) {
          this.roundArr.map((n) => {
            // for ( let j=0; j < this.model.booking_round.length; j++) {
            this.model.booking_round.map((l) => {
              if (l?.week) {
                this.cant_update_prop = true;
              }
              if (m?.id == l.part_id && n == l.round && l.part_number == m.part) {
                tmp.push({id: l.id, ordinal: l.ordinal, part_id: l.part_id, round: l.round, uid: l?.part_user?.id, part_number: l?.part_number, week: l?.week, status: l?.status});
              }
            })
          })
          this.dataBookingRound.push(tmp);
        })
      });
      console.log("this.dataBookingRound", this.dataBookingRound);
      // this.dataBookingRound = this.model.booking_round;
      // this.from_to = `${moment(this.model.start_visit_date).format("LL")} - ${moment(this.model.end_visit_date).format("LL")}`;
      this.start_booking_date = moment(this.model.start_booking_date).format("LL");
      this.end_booking_date = moment(this.model.end_booking_date).format("LL");
      this.componentKey++;
    }
    if (this.mode == 'edit') {
      setTimeout(() => {
        this.mode_edit++;
      }, 200);
    }
  },
  methods: {
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}&year=${new Date(this.model.end_booking_date)}`);
    },
    async getListProperty(){

      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('property',{"fields" : `id code name time_zone address booking{id} part {id part order_booking user {id fullname email}}`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_property.push({id : e.id, name :e.name, code: e.code, address: e.address, part: e.part, time_zone: e.time_zone});
        //}
      }
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          // booking: {id: {_is_null: true} },
          _or: {
            name: {_iregex: this.$commonFuction.search(search)},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property',{"fields" : `id code name time_zone address booking {id} part {id part order_booking user {id fullname email}}`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_property.push({id : e.id, name :e.name, code: e.code, address: e.address, part: e.part, time_zone: e.time_zone});
          //}
        }
        this.listProperty = list_property;
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      let property_add = document.getElementById("property");
      if (this.$commonFuction.isEmptyObject(this.selProperty)){
        property_add.setCustomValidity("Please choose a property for booking round!");
      }else {
        property_add.setCustomValidity("");
      }


      let from_to_add = document.getElementById("from_to");
      if (this.$commonFuction.isEmpty(this.from_to) || !this.from_to || this.from_to.length == 0){
        from_to_add.setCustomValidity("Please select the From - To time for this booking round!");
      }else if (this.from_to[0] == this.from_to[1]) {
        from_to_add.setCustomValidity("Please select the time to > from!");
      }else {
        from_to_add.setCustomValidity("");
      }


      let start_booking_date_add = document.getElementById("start_booking_date");
      if (this.$commonFuction.isEmpty(this.start_booking_date)){
        start_booking_date_add.setCustomValidity("Please select the start and end time of the booking!");
      }else {
        start_booking_date_add.setCustomValidity("");
      }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      console.log("this.from_to", this.from_to);
      console.log("partArr", this.dataBookingRound);
      var params = {
        property: this.selProperty,
        start_visit_date: this.mode == 'add' ? moment(this.from_to[0]) : moment(this.model.start_visit_date),
        end_visit_date: this.mode == 'add' ? moment(this.from_to[1]) : moment(this.model.end_visit_date),
        start_booking_date: moment(this.start_booking_date),
        end_booking_date: moment(this.end_booking_date),
        cant_update_prop: this.cant_update_prop,
        booking_round: this.dataBookingRound
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
</style>
