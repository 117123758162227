<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Create new booking",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      time_zone_local : moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editBooking' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Edit' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_BOOKING);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-booking' });
    },
    UpdateActive(key){
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if(key == 'lock'){
        this.active_check = false
      }else{
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': {'_eq': this.$route.params.id}
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success',res);
        if(key == 'lock'){
          var query_t =  `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      // console.log("params",params)
      let objects = {
        property_id: params.property?.id,
        start_visit_date: params.start_visit_date.format("YYYY-MM-DD"),
        end_visit_date: params.end_visit_date.format("YYYY-MM-DD"),
        start_booking_date: params.start_booking_date.format("YYYY-MM-DD"),
        end_booking_date: params.end_booking_date.format("YYYY-MM-DD"),
        updated_at: moment(new Date()),
        updated_by: this.$store.getters.user
      }
      var booking_round = params.booking_round;
      this.triggerSave = false;
      // var days = 14;
      if(this.mode == 'add'){
        objects.created_at = moment(new Date());
        objects.created_by = this.$store.getters.user;
        await this.$apollo.mutate({
            mutation: DataServices.insertData('booking'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              // let diff_date = params.end_visit_date.diff(params.start_visit_date, 'days');
              // let start_of_week = params.start_visit_date.startOf('week').day('Monday');
              // let end_of_week = moment(moment(start_of_week).add(13, 'days').format('YYYY/MM/DD')).endOf('week').day(0);
              // let date_week_arr = [{start: start_of_week, end: end_of_week}];
              // for(let i=1; i <= diff_date; i++) {
              //   if(i%days==0 && i != diff_date) {
              //     let start_d = moment(end_of_week.add(2, 'days').format('YYYY/MM/DD')).startOf('week').day('Monday');
              //     // moment(moment(params.start_visit_date).add((i+h), 'days').startOf('week').day('Monday').format('YYYY/MM/DD'));
              //     end_of_week = moment(moment(start_d).add(13, 'days').format('YYYY/MM/DD')).endOf('week').day(0)
              //     date_week_arr.push({start: start_d, end: end_of_week});
              //   }
              // }
              var date_week_arr = this.calculatePeriods(new Date(params.start_visit_date.format("YYYY-MM-DD")), new Date(params.end_visit_date.format("YYYY-MM-DD")));
              console.log("date_week_arr", date_week_arr);
              date_week_arr.map(async m => {
                let obj_w = {
                  week_number:moment(m.start).format('w'),
                  date_start:moment(m.start).format("YYYY-MM-DD"),
                  date_end: moment(m.end).format("YYYY-MM-DD"),
                  booking_id: returning.id,
                  property_id: params.property?.id,
                  index_week_id: `${moment(m.start).format('w')}${moment(m.start).format('YYYY')}`
                }
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('property_week'),
                    variables: {
                      objects: obj_w
                    }
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                });
              })
              if (booking_round.length > 0) {
                for (let i=0;i < booking_round.length; i++) {
                  for (let j=0;j < booking_round[i].length; j++) {
                    let obj = {
                      // week_id: booking_round[i][j]?.week?.id,
                      round: `${booking_round[i][j]?.round}`,
                      ordinal: booking_round[i][j]?.ordinal,
                      status: booking_round[i][j]?.status ?? 'ST005',
                      part_id: booking_round[i][j]?.part_id,
                      part_number: booking_round[i][j]?.part_number,
                      uid: booking_round[i][j]?.uid,
                      booking_id: returning.id
                    };
                    if (booking_round[i][j]?.round == 1 && booking_round[i][j]?.ordinal == 1) {
                      obj.date_start_book = params?.property?.time_zone ? moment.tz(params.start_visit_date, params?.property?.time_zone).format('YYYY/MM/DD') : params.start_visit_date;
                    }
                    await this.$apollo.mutate({
                        mutation: DataServices.insertData('booking_round'),
                        variables: {
                          objects: obj
                        }
                    }).then(async () => {
                      if ((i+1) == booking_round.length && (j+1) == booking_round[i].length) {
                        this.redirectEdit();
                      }
                    }).catch(async error => {
                        console.log('error', error);
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                            detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                            life: 3000
                        });
                        this.$commonFuction.is_loading(false);
                    });
                  }
                }
              } else {
                this.redirectEdit();
              }
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }else{
        // var user_id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('booking'),
            variables: variables
        }).then(async (res) => {
            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              // let checkRound = 0;
              // if (booking_round.length > 0) {
              //   for (let i=0;i < booking_round.length; i++) {
              //     for (let j=0;j < booking_round[i].length; j++) {
              //       if (booking_round[i][j]?.week?.id) {
              //         checkRound++;
              //       }
              //     }
              //   }
              // }
              if (!params.cant_update_prop) {
                var query_w = DataServices.deleteData('property_week')
                var variables_w = {
                  where_key: {
                    booking_id: {'_eq': returning.id}
                  },
                }
                if (params.property.id != this.models.property.id) {
                  variables_w.where_key.property_id = {'_eq': params.property?.id}
                }
                this.$apollo.mutate({
                  mutation: query_w,
                  variables: variables_w
                })
                // let diff_date = params.end_visit_date.diff(params.start_visit_date, 'days');
                // let start_of_week = params.start_visit_date.startOf('week').day('Monday');
                // let end_of_week = moment(moment(start_of_week).add(13, 'days').format('YYYY/MM/DD')).endOf('week').day(0);
                // let date_week_arr = [{start: start_of_week, end: end_of_week}];
                // for(let i=1; i <= diff_date; i++) {
                //   if(i%days==0 && i != diff_date) {
                //     let start_d = moment(end_of_week.add(2, 'days').format('YYYY/MM/DD')).startOf('week').day('Monday');
                //     // moment(moment(params.start_visit_date).add((i+h), 'days').startOf('week').day('Monday').format('YYYY/MM/DD'));
                //     end_of_week = moment(moment(start_d).add(1, 'days').format('YYYY/MM/DD')).endOf('isoWeek')
                //     date_week_arr.push({start: start_d, end: end_of_week});
                //   }
                // }
                var date_week_arr = this.calculatePeriods(new Date(params.start_visit_date.format("YYYY-MM-DD")), new Date(params.end_visit_date.format("YYYY-MM-DD")));
                console.log("date_week_arr", date_week_arr);
                date_week_arr.map(async m => {
                  let obj_w = {
                    week_number:moment(m.start).format('w'),
                    date_start:moment(m.start).format("YYYY-MM-DD"),
                    date_end: moment(m.end).format("YYYY-MM-DD"),
                    booking_id: returning.id,
                    property_id: params.property?.id,
                    index_week_id: `${moment(m.start).format('w')}${moment(m.start).format('YYYY')}`
                  }
                  await this.$apollo.mutate({
                      mutation: DataServices.insertData('property_week'),
                      variables: {
                        objects: obj_w
                      }
                  }).catch(async error => {
                      console.log('error', error);
                      this.$toast.add({
                          severity: 'error',
                          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                          life: 3000
                      });
                  });
                })
              }
              if (!params.cant_update_prop) {
                var query_b = DataServices.deleteData('booking_round')
                var variables_b = {
                  where_key: {
                    booking_id: {'_eq': returning.id}
                  },
                }

                await this.$apollo.mutate({
                  mutation: query_b,
                  variables: variables_b
                })
              }
              if (booking_round.length > 0) {
                for (let i=0;i < booking_round.length; i++) {
                  for (let j=0;j < booking_round[i].length; j++) {
                    let obj = {
                      week_id: booking_round[i][j]?.week?.id,
                      round: `${booking_round[i][j]?.round}`,
                      ordinal: booking_round[i][j]?.ordinal,
                      status: booking_round[i][j]?.status ?? 'ST005',
                      part_id: booking_round[i][j]?.part_id,
                      part_number: booking_round[i][j]?.part_number,
                      uid: booking_round[i][j]?.uid,
                      booking_id: returning.id
                    };
                    if (booking_round[i][j]?.round == 1 && booking_round[i][j]?.ordinal == 1) {
                      obj.date_start_book = params?.property?.time_zone ? moment.tz(params.start_visit_date, params?.property?.time_zone).format('YYYY/MM/DD') : params.start_visit_date;
                    }
                    if (params.cant_update_prop) {
                      let variables_ = {
                        'data_update': obj,
                        'where_key': {
                          'id': {'_eq': booking_round[i][j].id}
                        }
                      }
                      await this.$apollo.mutate({
                          mutation: DataServices.updateData('booking_round'),
                          variables: variables_
                      }).then(async () => {
                        if ((i+1) == booking_round.length && (j+1) == booking_round[i].length) {
                          this.redirectEdit();
                        }
                      }).catch(async error => {
                          console.log('error', error);
                          this.$toast.add({
                              severity: 'error',
                              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                              life: 3000
                          });
                          this.$commonFuction.is_loading(false);
                      });
                    } else {
                      await this.$apollo.mutate({
                          mutation: DataServices.insertData('booking_round'),
                          variables: {
                            objects: obj
                          }
                      }).then(async () => {
                        if ((i+1) == booking_round.length && (j+1) == booking_round[i].length) {
                          this.redirectEdit();
                        }
                      }).catch(async error => {
                          console.log('error', error);
                          this.$toast.add({
                              severity: 'error',
                              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                              life: 3000
                          });
                          this.$commonFuction.is_loading(false);
                      });
                    }
                  }
                }
              } else {
                this.redirectEdit();
              }
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }
      this.$commonFuction.is_loading(false);
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-booking'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    calculatePeriods(startDate, endDate) {
      const periods = [];
      let currentStart = new Date(startDate); // Start from the input start date
      currentStart.setHours(0, 0, 0, 0); // Ensure no time component is included
      
      const end = new Date(endDate);
      end.setHours(0, 0, 0, 0); // Ensure no time component is included

      while (currentStart <= end) {
        // Calculate the end of the current period by adding 13 days to the current start date (to make it exactly 14 days including the start)
        let currentEnd = new Date(currentStart.getTime() + (13 * 24 * 60 * 60 * 1000)); // 13 days in milliseconds (so the period is exactly 14 days)

        // If the currentEnd exceeds the given endDate, set the currentEnd to the given endDate
        if (currentEnd > end) {
          currentEnd = new Date(end);
        }

        // Push the current period into the array
        periods.push({
          start: new Date(currentStart),
          end: currentEnd
        });

        // Move the start to the next period (1 day after the currentEnd)
        currentStart = new Date(currentEnd.getTime() + (1 * 24 * 60 * 60 * 1000)); // Move start to the next day
      }

      return periods;
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('booking', {
          "fields" : `id property {id code name time_zone address part {id part order_booking user {id fullname email}}} booking_round {id part_id round status ordinal part_user{id fullname email} part_number week{week_number date_start date_end}} start_visit_date end_visit_date start_booking_date end_booking_date`
        }),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {'_eq': false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 18px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.p-toolbar-group-right {
  flex-basis: 30%;
}
.p-button-warning.p-button {
  margin: 0 10px;
}
</style>