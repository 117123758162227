<template>
    <form id="frm_action_matrix">
        <div class="matrix-box mt-3 pl-3">
            <table id="matrix-table">
                <thead>
                    <tr>
                        <td class="head-color-blank" colspan="1" rowspan="1"></td>
                        <td class="head-color" colspan="1" rowspan="1" v-for="(item, index) in roundArr" :key="`headColor-${index}`">
                            <div>{{ `Round ${item}` }}</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dataMatrix" :key="`bodyData-${index}`">
                        <td class="body-color" rowspan="1" colspan="1">
                            <div><b>{{ item.part_number }}</b></div>
                            <div v-if="dataMatrix.length > 0 && dataMatrix[index]">
                                <div v-if="dataMatrix[index].user">{{ dataMatrix[index].user.fullname }}</div>
                                <div>(Part {{ dataMatrix[index].part }})</div>
                            </div>
                        </td>
                        <td rowspan="1" colspan="1" v-for="(j, ind) in roundArr" :key="`bodyValue-${ind}`" @click="dataBookRound[index] && dataBookRound[index][ind] ? showPopupChooseDate(dataBookRound[index][ind], dataMatrix[index]) : null">
                            <div v-if="dataBookRound[index] && dataBookRound[index][ind]" class="d-flex flex-column">
                                <div class="align-self-start col-12"><b>{{dataBookRound[index][ind].ordinal}}</b></div>
                                <!-- <div v-if="dataBookRound[index][ind].status">
                                    {{dataBookRound[index][ind].status}}
                                </div> -->
                                <div class="col-12" v-if="dataBookRound[index][ind].status">
                                    <div v-if="dataBookRound[index][ind].week">
                                        {{`Week ${dataBookRound[index][ind].week.week_number}-${dataBookRound[index][ind].week.week_number + 1} (${getFilterDate(dataBookRound[index][ind].week.date_start)} - ${getFilterDate(dataBookRound[index][ind].week.date_end)})`}}
                                    </div>
                                    <div v-else>
                                        {{$constants.BOOKING_STATUS[dataBookRound[index][ind].status]}}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <Dialog header="Choose a time" :visible.sync="showPopup" :style="{width: '50vw'}" >
                <form id="frm_action_week">
                    <p>Choose a time for this round:</p>
                    <div>
                        <div>
                            <label class="p-col-fixed txt-right">Round</label>
                            <div class="p-col">
                                <InputText type="text" class="" v-model="dataPopup.round" :disabled="true"/>
                            </div>
                        </div>
                        
                        <div>
                            <label class="p-col-fixed txt-right" style="text-transform: capitalize;">Part {{dataPopup.part.part_number}}</label>
                            <div class="p-col">
                                <InputText type="text" class="" v-model="dataPopup.part.name" :disabled="true"/>
                            </div>
                        </div>
                        
                        <div class="mb-4">
                            <label class="p-col-fixed txt-right txt-required">Week</label>
                            <div class="p-col">
                                <v-select style="position: absolute; bottom: 10%;" title="Week" class="w-100 pr-5 mb-4" @search="listWeek" placeholder="Choose" v-model="dataPopup.week" :options="listWeek" label="name"  >
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Empty data
                                    </span>
                                </v-select>
                                <input class="input_tmp_validator" id="week_valid">
                            </div>
                        </div>
                    </div>
                    
                </form>
                <template #footer>
                    <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="showPopup = false"/>
                    <Button label="Confirm" icon="pi pi-check" @click="confirmPopup"/>
                </template>
            </Dialog>
        </div>
    </form>
</template>
  
<script>
import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
export default {
    name: "matrixComponent",
    components: {
    },
    props: {
      value: Array,
      property: Object,
      listUid: Array,
      formula: String,
      dataColor: Array,
      dataBook: Array,
      show: Boolean,
      mode: String,
      booking_id: String,
      roundArr: Array
    },
    async created() {
        this.dataMatrix = this.$props.value;
        this.dataBookRound = this.dataBook;
        await this.getListWeek();
    },
    watch: {
        // async "dataBookRound"() {
        //     this.mapDataFormat();
        // }
    },
    data() {
      return {
        dataArr: [1,2,3,4,5],
        dataBookRound: [],
        dataMatrix: [],
        time_zone_local : moment.tz.guess(true),
        dataPopup: {
            ordinal: null,
            part_id: null,
            round: null,
            part: {
                part_number: null,
                name: null,
            },
            week: null
        },
        showPopup: false,
        listWeek: []
      };
    },
    methods: {
        async validateData() {
            this.validationErrors = {};


            let week_add = document.getElementById("week_valid");
            if (this.$commonFuction.isEmptyObject(this.dataPopup.week)){
                week_add.setCustomValidity("Please choose week!");
            }else {
                week_add.setCustomValidity("");
            }


            var inpObj = document.getElementById("frm_action_week");
            if (!inpObj.checkValidity()){
                console.log('test=====================');
                inpObj.reportValidity();
                return true;
            }
        },
        async confirmPopup() {
            if (await this.validateData()) {
                this.$commonFuction.is_loading(false);
                return
            }
            this.dataBookRound.map(m => {
                var dataF = m.filter(f => f.part_id == this.dataPopup.part_id && f.ordinal == this.dataPopup.ordinal && f.round == this.dataPopup.round);
                if (dataF.length > 0) {
                    var index = m.indexOf(dataF[0]);
                    dataF[0].week = this.dataPopup.week;
                    dataF[0].status = "ST002";
                    dataF[0].is_new = true;
                    console.log("dataF[0]", dataF[0]);
                    m.splice(index, 1, dataF[0]);
                }
            });
            this.dataPopup = {
                ordinal: null,
                part_id: null,
                round: null,
                part: {
                    part_number: null,
                    name: null,
                },
                week: null
            };
            this.showPopup = false;
        },
        async getListWeek() {
            if (!this.property?.id) {
                return;
            }
            var where_key = {
                property_id: {_eq: this.property?.id}
            }
            if (this.mode == 'edit') {
                where_key.booking_id = {_eq: this.booking_id}
            }
            let {data} = await this.$apollo.query({
                query: DataServices.getList('property_week',{"fields" : `id week_number date_start date_end booking_round{id}`}),
                variables: {
                    where_key: where_key,
                    orderBy:[{date_start : 'asc'}]
                },
                fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_week = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
                const e = list[i];
                if (!e?.booking_round?.id) {
                    list_week.push({id : e.id, week_number: e.week_number, date_start: e.date_start, date_end: e.date_end, name: `Week ${e.week_number} - ${e.week_number + 1} (${this.getFilterDate(e.date_start)} - ${this.getFilterDate(e.date_end)})`});
                }
            }
            this.listWeek = list_week
        },
        showPopupChooseDate(data, part) {
            if (this.mode != 'add' && (['ST001', 'ST003'].includes(data?.status) || data?.is_new)) {
                var week =  data?.week ? {
                    id: data.week?.id,
                    name: `Week ${data.week.week_number} - ${data.week.week_number + 1} (${this.getFilterDate(data.week.date_start)} - ${this.getFilterDate(data.week.date_end)})`,
                    week: data.week
                } : null
                this.showPopup = true;
                this.dataPopup = {
                    id: data?.id,
                    ordinal: data.ordinal,
                    part_id: data.part_id,
                    round: data.round,
                    part: {
                        part_number: part.part,
                        name: `${part.user.fullname} - ${part.user.email}`,
                    },
                    week: week
                }   
            }
        },
        getFilterDate(date) {
            return moment.tz(date, this.time_zone_local).format("LL");
        },
        mapDataFormat() {
            var dataBookingRound = [];
            if (this.dataBookRound.length > 0 && this.dataBook.length > 0) {
                return Promise.all(
                    this.dataArr.map((m, ind)=> {
                        var tmp = [];
                        // for (let i =0; i < this.dataArr.length; i++) {
                        this.dataArr.map((n, i)=> {
                            if (this.dataBookRound[ind] && this.dataBookRound[ind][i]) {
                                tmp.push(this.dataBookRound[ind][i]);
                            } else {
                                let a = m - i + this.dataArr.length;
                                if (a > this.dataArr.length) {
                                    a = a - this.dataArr.length;
                                }
                                tmp.push({ordinal: a, part_id: this?.dataMatrix[ind]?.id, round: (i+1)});
                            }
                        });
                        dataBookingRound.push(tmp);
                    })
                ).then(() => {
                    console.log("dataBookingRound", dataBookingRound);
                    console.log("this.dataBookingRound", this.dataBookRound);
                    var is_same = (dataBookingRound.length == this.dataBookRound.length) && this.dataBookRound.every(function(element, index) {
                        return element === dataBookingRound[index];
                    })
                    if (!is_same) {
                        this.$emit("updateMatrix", dataBookingRound);
                    }
                })
            }
        },
    }
};
</script>

<style lang="scss">
    #matrix-table {
        td {
            width: 127px;
            min-height: 127px;
            height: auto;
            border: 1px solid #EAEAEA;
            text-align: center;
            color: #000;
            padding: 20px 5px;
            cursor: pointer;
        }
    }
    .head-color, .body-color {
        background-color: #F3F4F7;
    }
    .head-color{
        font-weight: bold;
    }
    .head-color-blank, .head-color {
        height: 57px !important;
    }
    .head-color-blank {
        border: none !important;
    }
    .vs__dropdown-menu {
        width: 90%;
        padding-bottom: 3em !important;
    }
</style>
